import React, { Component } from 'react';
import "./Footer.scss";

class Footer extends Component {

    render() {
        //const footerData = this.props.data;
 
        return (      
            <footer className='footer'>
                <div className='header'>Always read and follow label directions</div>
                <div className='text'>INVIGOR is a registered trade-mark of BASF. used under license by BASF Canada Inc. © 2024<br></br>
                                    DEKALB<sup>®</sup> is a trademark of Bayer Group. © 2024 Bayer Group. All rights reserved.<br></br>
                                    Brevant<sup>®</sup> brand canola products and Pioneer<sup>®</sup> brand canola products are trademarks of Corteva Agriscience and its affiliated companies. © 2024 Corteva.<br></br>
                                    Corteva recommends that all hybrids with a shatter score rating of 6 or less be managed according to guidelines for swathing (60%-80% seed color change).</div> 
                <div className="nav"> 
                    <a 
                        target="_blank" 
                        href="https://agriculture.basf.ca/west/contact-us.html" rel="noopener noreferrer"
                        data-tealium-category="link" 
                        data-tealium-action="click footer-link" 
                        data-tealium-value="BASF||https://agriculture.basf.ca/west/contact-us.html" 
                        data-tealium-type="BASFContact-link" 
                    >Contact | </a> 
                    <a 
                        target="_blank" 
                        href="https://www.basf.com/ca/en/legal/disclaimer.html" 
                        rel="noopener noreferrer"
                        data-tealium-category="link" 
                        data-tealium-action="click footer-link" 
                        data-tealium-value="twitter||https://www.basf.com/ca/en/legal/disclaimer.html" 
                        data-tealium-type="BASFDisclaimer-link" 
                    >Disclaimer | </a> 
                    <a 
                        target="_blank" 
                        href="https://www.basf.com/ca/en/legal/credits.html" 
                        rel="noopener noreferrer"
                        data-tealium-category="link" 
                        data-tealium-action="click footer-link" 
                        data-tealium-value="BASF||https://www.basf.com/ca/en/legal/credits.html" 
                        data-tealium-type="BASFCredits-link" 
                    >Credits | </a> 
                    <a 
                        target="_blank" 
                        href="https://www.basf.com/ca/en/legal/data-protection.html" 
                        rel="noopener noreferrer"
                        data-tealium-category="link" 
                        data-tealium-action="click footer-link" 
                        data-tealium-value="BASF||https://www.basf.com/ca/en/legal/data-protection.html" 
                        data-tealium-type="BASFPrivacyPolicy-link" 
                    >Privacy Policy | </a> 
                    <a 
                        target="_blank" 
                        href="https://agro.basf.ca/basf_solutions/seedsandsystems/invigor_canola_west.html" 
                        rel="noopener noreferrer"
                        data-tealium-category="link" 
                        data-tealium-action="click footer-link" 
                        data-tealium-value="AgSolution Products||https://agro.basf.ca/basf_solutions/seedsandsystems/invigor_canola_west.html" 
                        data-tealium-type="AgsolutionProducts-link" 
                    >Products | </a>
                    <a 
                        target="_blank" 
                        href="https://agro.basf.ca/basf_solutions/index/main_west.html" 
                        rel="noopener noreferrer"
                        data-tealium-category="link" 
                        data-tealium-action="click footer-link" 
                        data-tealium-value="AgSolution||https://agro.basf.ca/basf_solutions/index/main_west.html" 
                        data-tealium-type="BASFAgsolution-link" 
                    >AgSolutions.ca</a>
                </div> 
            </footer> 
        );
    }
}

export default Footer;
